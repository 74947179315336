import { useEffect } from "react";
import { toast } from "react-toastify";
import { axiosToken } from "../config/axios";
import useAuth from "../hooks/useAuth";

export default function useAxiosToken() {
  const { accessToken, logout } = useAuth();

  useEffect(() => {
    const requestInterceptor = axiosToken.interceptors.request.use(
      function (config) {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    const responseInterceptor = axiosToken.interceptors.response.use(
      function (response) {
        return response;
      },
      async function (error) {
        if (error?.response?.status === 403) {
          logout();
        } else if (error?.code !== "ERR_CANCELED") {
          toast.error(error?.message || "An error occurred", {
            toastId: "axios",
          });
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosToken.interceptors.request.eject(requestInterceptor);
      axiosToken.interceptors.response.eject(responseInterceptor);
    };
  }, [accessToken, logout]);
  return axiosToken;
}
