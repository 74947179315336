import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link,
} from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";

function MenuItem({ name, icon, path, mini }) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Link
      component={RouterLink}
      to={path}
      underline="none"
      color={(theme) => theme.palette.text.main}
      //   color={(isActive) =>
      //     isActive ? theme.palette.primary.main : theme.palette.text.main
      //   }
    >
      <ListItem disablePadding onClick={handleClick}>
        <ListItemButton>
          <ListItemIcon sx={{ fontSize: "1.5rem", color: "inherit" }}>
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={name}
            sx={{
              "& span": { color: "inherit" },
              display: mini ? "none" : "inherit",
            }}
          />
        </ListItemButton>
      </ListItem>
    </Link>
  );
}

MenuItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  path: PropTypes.string,
  icon: PropTypes.object,
  mini: PropTypes.bool,
};

export default React.memo(MenuItem);
