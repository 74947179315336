import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import React, { useState } from "react";
import PropTypes from "prop-types";

function MultipleSelector({ selectedHandler, options }) {
  const [value, setValue] = useState(options[0]?.value);

  const handleChange = (event) => {
    const selected = event.target.value;
    setValue(selected);
    selectedHandler(selected);
  };

  return (
    <FormControl>
      <Select
        value={value}
        onChange={handleChange}
        bgcolor="white"
        sx={{
          borderRadius: "0.7rem",
          boxShadow: "rgba(0,0,0,0.35) 0px 5px 15px",
          backgroundColor: "white",
          maxWidth: "min-content",
        }}
      >
        {options?.map((option) => {
          return (
            <MenuItem value={option.value} key={option.value}>
              <Typography variant="subtitle2">{option.label}</Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

MultipleSelector.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  selectedHandler: PropTypes.func,
};

export default React.memo(MultipleSelector);
