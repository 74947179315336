import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { Outlet } from "react-router-dom";

import ResponsiveDrawer, { largeDrawerWidth as initWidth } from "./Drawer";
function Layout() {
  const [drawerWidth, setDrawerWidth] = useState(initWidth);
  return (
    <Stack direction={{ xs: "column", sm: "row" }} justifyContent="center">
      <ResponsiveDrawer
        setDrawerWidth={setDrawerWidth}
        drawerWidth={drawerWidth}
      />
      <Box
        component="main"
        flexGrow={1}
        maxWidth={{ sm: `calc(100% - ${drawerWidth}px)` }}
      >
        <Outlet />
      </Box>
    </Stack>
  );
}

export default Layout;
