import { Typography } from "@mui/material";
import { useMemo, useCallback } from "react";
import ContentContainer from "../components/ContentContainer";
import Table from "../components/Table";

const statusPriority = {
  OnRide: 0,
  Available: 1,
  Offline: 2,
  Blocked: 3,
  Checking: 4,
};

export default function Captains() {
  const columns = useMemo(
    () => [
      {
        accessorKey: "name.ar",
        header: "Name",
        size: 10,
        Cell: ({ cell }) => {
          return <span>{cell?.row.original.name.ar}</span>;
        },
      },
      {
        accessorKey: "phoneNumber",
        header: "Phone Number",
        size: 10,
        Cell: ({ cell }) => {
          return <span>+249 {cell?.row.original.phoneNumber}</span>;
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 10,
        Cell: ({ cell }) => {
          const status = cell?.row.original.status;
          let color = { borderRadius: 3 };
          switch (status) {
            case "Available":
              color = "green";
              break;

            case "Offline":
              color = "tomato";
              break;

            case "OnRide":
              color = (theme) => theme.palette.yellow.main;
              break;

            default:
              color = "gray";
              break;
          }
          return (
            <Typography
              border={1}
              borderRadius={3}
              px={1}
              color={color}
              variant="caption"
            >
              {cell?.row.original.status}
            </Typography>
          );
        },
      },
      {
        accessorKey: "since",
        header: "Since",
        size: 10,
      },
      {
        accessorKey: "lastSeen",
        header: "Last Seen",
        size: 10,
      },
    ],
    []
  );

  const sortData = useCallback((data) => {
    return data.sort((a, b) => {
      return statusPriority[a.status] - statusPriority[b.status];
    });
  }, []);

  return (
    <ContentContainer>
      <Typography variant="h4" my={3}>
        Delivery Captains
      </Typography>
      <Table
        url="captains"
        columns={columns}
        autoRefreshDuration={120000}
        sortData={sortData}
      />
    </ContentContainer>
  );
}
