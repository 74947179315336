import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";

import ButtonsGroup from "../components/ButtonsGroup";
import MultipleSelector from "../components/MultipleSelectRideType";
import Map, { defaultBoundary } from "../components/Map";
import useAuth from "../hooks/useAuth";
import useAxiosToken from "../hooks/useAxiosToken";

import DriverInfo from "../components/DriverInfo";

const rideStatus = [
  { value: 5, label: `All ( - )` },
  { value: 0, label: `On Ride ( - )` },
  { value: 4, label: `Checking ( - ) ` },
  { value: 1, label: `Idle ( - )` },
];

export default function LiveTracking() {
  const { viewRides } = useAuth();
  const [boundary, setBoundary] = useState(defaultBoundary);
  const [cars, setCars] = useState([]);
  const [rideType, setType] = useState(viewRides[0]);

  const [status, setStatus] = useState(rideStatus);
  const [selectedStatus, setSelected] = useState(rideStatus[0].value);

  const [selectedCarID, setSelectedCarID] = useState();
  const [driverData, setDriverData] = useState();

  const previousController = useRef();
  const axiosToken = useAxiosToken();

  const fetchData = useCallback(async () => {
    // console.log("fetch data");
    if (previousController.current) {
      previousController.current.abort();
    }

    const controller = new AbortController();
    const signal = controller.signal;

    previousController.current = controller;

    try {
      const body = {
        boundary,
        type: rideType,
      };

      const response = await axiosToken.post("/live", body, {
        signal,
      });
      const idle = response.data?.idleCount;
      const active = response.data?.onRideCount;
      const checking = response.data?.checkingCount;

      setCars(response.data?.cars);
      setStatus([
        { value: 5, label: `All (${idle + active + checking})` },
        { value: 0, label: `On Ride (${active})` },
        { value: 4, label: `Checking (${checking}) ` },
        { value: 1, label: `Idle (${idle})` },
      ]);
    } catch (error) {
      // console.log(error.message);
    }
  }, [previousController, boundary, rideType, setCars, axiosToken]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 8500);

    fetchData();

    return () => {
      clearInterval(interval);
    };
  }, [fetchData]);

  const fetchDriverInfo = useCallback(
    async (id) => {
      setSelectedCarID(id);
      try {
        const response = await axiosToken.get("/live?id=" + id);
        setDriverData(response.data);
      } catch (error) {}
    },
    [axiosToken]
  );

  const closeInfoHandler = useCallback(() => {
    setDriverData(null);
    setSelectedCarID(null);
  }, []);

  const rideTyleHandler = useCallback(
    (FilterRideType) => {
      setType(FilterRideType);
      closeInfoHandler();
    },
    [setType, closeInfoHandler]
  );

  const rideStatusHandler = (selectedStatus) => {
    setSelected(selectedStatus);
    closeInfoHandler();
  };

  const updateMapBounds = useCallback(
    (bounds) => {
      setBoundary(bounds);
    },
    [setBoundary]
  );

  const rideOptions = useMemo(
    () =>
      viewRides.map((type) => {
        return {
          label:
            type.length <= 2
              ? type
              : type.charAt(0) + type.toLowerCase().slice(1),
          value: type,
        };
      }),
    [viewRides]
  );

  const markers =
    selectedStatus === 5
      ? cars
      : cars?.filter((car) => car?.status === selectedStatus);

  return (
    <Box position="relative">
      <Stack
        direction={{ sm: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="flex-start"
        position="absolute"
        left={20}
        top={17}
        zIndex={500}
        gap={3}
      >
        <Stack gap={3}>
          <Stack direction={{ sm: "column", md: "row" }} gap={3}>
            <ButtonsGroup buttonsData={status} onClick={rideStatusHandler} />

            <MultipleSelector
              selectedHandler={rideTyleHandler}
              options={rideOptions}
            />
          </Stack>

          {selectedCarID && (
            <DriverInfo
              driverData={driverData}
              selectedCarID={selectedCarID}
              closeHandler={closeInfoHandler}
            />
          )}
        </Stack>
      </Stack>
      <Box height={{ xs: "calc(100vh - 56px)", sm: "100vh" }}>
        <Map
          markers={markers}
          updateMapBounds={updateMapBounds}
          markerClickHandler={fetchDriverInfo}
          selectedCarID={selectedCarID}
        />
      </Box>
    </Box>
  );
}
