import { createTheme } from "@mui/material/styles";

const palette = {
  type: "light",
  primary: {
    main: "#45A247",
  },
  secondary: {
    main: "#EFD154",
  },
  lime: {
    main: "#59DE5C",
  },
  grey: {
    main: "#565656",
  },
  text: {
    main: "#000000DE",
  },
  yellow: {
    main: "#ffcd00",
  },
  background: {
    main: "#eaf9d959",
  },
};

const theme = createTheme({
  typography: {
    allVariants: {
      color: "#292929",
    },
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          lineHeight: "1.5rem",
          textTransform: "none",
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            fontSize: "1rem",
            padding: "0.5rem 3rem",
            borderRadius: "1.5rem",
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "#f8fafc",
        },
      },
    },
  },
});

export default theme;
