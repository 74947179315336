import PropTypes from "prop-types";
import {
  MapContainer,
  TileLayer,
  Marker,
  ZoomControl,
  GeoJSON,
  Circle,
} from "react-leaflet";
import L, { Icon } from "leaflet";
import { useEffect, useState } from "react";

import carIcon from "../assets/icons/car.png";
import selectedIcon from "../assets/icons/selected_car.png";
import mapData from "../assets/map.json";
const goCarIcon = new Icon({
  iconUrl: carIcon,
  iconSize: [40, 20],
});

const selectedCarIcon = new Icon({
  iconUrl: selectedIcon,
  iconSize: [40, 20],
});
// const L = window.L;

export const defaultCenter = [15.603693, 32.529439];
export const defaultBoundary = {
  north: 15.610926,
  south: 15.589515,
  east: 32.544445,
  west: 32.513894,
};

function Map({ markers, updateMapBounds, markerClickHandler, selectedCarID }) {
  const [map, setMap] = useState(null);
  const [loaded, setloaded] = useState(false);

  //get map bounds when moved
  useEffect(() => {
    const onMove = () => {
      const newBounds = map.getBounds();
      const boundary = {
        north: newBounds?.["_northEast"]?.lat,
        south: newBounds?.["_southWest"]?.lat,
        east: newBounds?.["_northEast"]?.lng,
        west: newBounds?.["_southWest"]?.lng,
      };
      updateMapBounds(boundary);
    };

    if (map) {
      map.on("move", onMove);
      if (!loaded) {
        setloaded(true);
        map.locate({ setView: true, maxZoom: 15 });
      }
    }

    return () => {
      if (map) {
        map.off("move", onMove);
      }
    };
  }, [map, loaded, updateMapBounds]);

  const pointToLayer = (geoJsonPoint, latlng) => {
    console.log(geoJsonPoint);
    const marker = L.marker(latlng);
    const circle = L.circle(latlng, { radius: 2000 }); //radius in meters
    return L.featureGroup([marker, circle]).on({
      click: (e) => console.log("clicked"),
      // setStyle:
    });
  };

  return (
    <MapContainer
      center={defaultCenter}
      zoom={15}
      scrollWheelZoom={true}
      ref={setMap}
      zoomControl={false}
      style={{ height: "100%", minHeight: "100%" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {markers?.map((marker) => {
        const carID = marker?.["car_id"];
        const icon = selectedCarID === carID ? selectedCarIcon : goCarIcon;
        return (
          <Marker
            // key={car?.["car_id"]}
            key={Math.random()}
            icon={icon}
            position={[marker?.latitude, marker?.longitude]}
            onClick={markerClickHandler}
            eventHandlers={{
              click: markerClickHandler.bind(this, carID),
            }}
          />
        );
      })}
      <ZoomControl position="bottomleft" />
      {/* <GeoJSON data={mapData} pointToLayer={pointToLayer} /> */}
    </MapContainer>
  );
}

Map.propTypes = {
  markers: PropTypes.array,
  updateMapBounds: PropTypes.func,
  markerClickHandler: PropTypes.func,
  selectedCarID: PropTypes.number,
};

export default Map;
