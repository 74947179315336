import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import ToastContainer from "./components/ToastContainer";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./store/auth-cxt";

import "./index.css";
import "react-toastify/dist/ReactToastify.css";

import theme from "./config/theme";
import { GlobalStyles } from "@mui/material";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <GlobalStyles
            styles={{
              body: { backgroundColor: theme.palette.background.main },
            }}
          />
          <ToastContainer />
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);
