import PropTypes from "prop-types";

import React, { useMemo } from "react";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  Divider,
} from "@mui/material";
import menuItems from "../../config/menuItems";
import MenuItem from "./MenuItem";

import useAuth from "../../hooks/useAuth";

import MenuIcon from "@mui/icons-material/Menu";
import { FiLogOut } from "react-icons/fi";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";

import logo from "../../assets/images/green-logo.png";
import { ID } from "../../config/routes";

export const largeDrawerWidth = 200;
const miniDrawerWidth = 60;

function ResponsiveDrawer(props) {
  const { window, setDrawerWidth, drawerWidth } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { logout, viewRides } = useAuth();

  const handleDrawerToggle = () => {
    // setDrawerWidth(largeDrawerWidth);
    setMobileOpen(!mobileOpen);
  };

  const drawer = useMemo(() => {
    const mini = +drawerWidth === miniDrawerWidth;
    return (
      <Box>
        <IconButton
          sx={{
            my: 2,
            display: "block",
            mr: 1,
            ml: "auto",
            transition: "0.5s ease",
          }}
          onClick={() => {
            setDrawerWidth(
              +drawerWidth === largeDrawerWidth
                ? miniDrawerWidth
                : largeDrawerWidth
            );
          }}
        >
          {mini ? <HiOutlineChevronRight /> : <HiOutlineChevronLeft />}
        </IconButton>
        <img
          src={logo}
          alt=""
          style={{
            width: "80%",
            margin: "3rem auto 2rem",
            display: "block",
            transition: "0.3s ease",
          }}
        />
        <Divider
          sx={{
            mx: "1rem",
            my: "1rem",
            transition: "0.5s ease",
          }}
        />
        <Stack>
          <List>
            {menuItems.map((item) => {
              if (item.id === ID.CAPTAINS) {
                return viewRides?.length === 1 ? (
                  <MenuItem
                    key={item.id}
                    name={item.name}
                    icon={item.icon}
                    path={item.path}
                    mini={mini}
                  />
                ) : null;
              }

              return (
                <MenuItem
                  key={item.id}
                  name={item.name}
                  icon={item.icon}
                  path={item.path}
                  mini={mini}
                />
              );
            })}
          </List>

          <ListItem key="logout" disablePadding onClick={logout}>
            <ListItemButton>
              <ListItemIcon
                sx={{
                  fontSize: "1.5rem",
                  color: (theme) => theme.palette.text.main,
                }}
              >
                {<FiLogOut />}
              </ListItemIcon>
              {
                <ListItemText
                  primary={"Logout"}
                  sx={{ display: mini ? "none" : "inherit" }}
                />
              }
            </ListItemButton>
          </ListItem>
        </Stack>
      </Box>
    );
  }, [logout, drawerWidth, viewRides?.length, setDrawerWidth]);

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="static"
        sx={{
          display: { xs: "block", sm: "none" },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="white" noWrap>
            GO
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              transition: "0.5s ease",
              maxWidth: drawerWidth,
              overflow: "hidden",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;
