import { ID, PATHS } from "./routes";
import { RiRoadMapLine } from "react-icons/ri";
import { MdOutlineSportsMotorsports } from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";
import { BsClockHistory } from "react-icons/bs";

const menuItems = [
  {
    id: ID.UNASSIGNED,
    path: PATHS.UNASSIGNED,
    name: "Unassigned Deliveries",
    icon: <BsClockHistory />,
  },
  {
    id: ID.LIVE_TRACKING,
    path: PATHS.LIVE_TRACKING,
    name: "Live Tracking",
    icon: <RiRoadMapLine />,
  },
  {
    id: ID.CAPTAINS,
    path: PATHS.CAPTAINS,
    name: "Delivery Captains",
    icon: <MdOutlineSportsMotorsports />,
  },
  {
    id: ID.REPORT,
    path: PATHS.REPORT,
    name: "Analytics Report",
    icon: <TbReportAnalytics />,
  },
];

export default menuItems;
