import { ToastContainer as ToastifyContainer } from "react-toastify";

export default function ToastContainer() {
  return (
    <ToastifyContainer
      position="top-right"
      autoClose={7000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
}
