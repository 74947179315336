import { Suspense, useEffect, useMemo, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import Loader from "./components/Loader";
import { protectedRoutes, unprotected } from "./config/routes";
import Layout from "./components/Layout/Layout";
import io from "socket.io-client";
import { Button, Stack } from "@mui/material";
import { PATHS } from "./config/routes";
import { toast } from "react-toastify";
import { useCallback } from "react";
import { BASE_URL } from "./config/axios";

// const socket = io(`${BASE_URL}/ltf`);
// const socket = io(``);

function App() {
  // const [isConnected, setIsConnected] = useState(socket.connected);
  const { accessToken } = useAuth();
  const navigate = useNavigate();
  // const location = useLocation();

  // const Msg = useCallback(
  //   ({ closeToast, toastProps }) => (
  //     <Stack
  //       flexDirection="row"
  //       alignItems="center"
  //       alignContent="center"
  //       gap={2}
  //     >
  //       Unassigned delivery
  //       <Button
  //         size="small"
  //         variant="contained"
  //         sx={{
  //           alignSelf: "flex-start",
  //           // borderRadius: "0.2rem",
  //           fontSize: "0.8rem",
  //           paddingX: 1,
  //           paddingY: 0.3,
  //         }}
  //         onClick={() => navigate(PATHS.UNASSIGNED)}
  //       >
  //         Navigate
  //       </Button>
  //     </Stack>
  //   ),
  //   []
  // );

  // useEffect(() => {
  //   socket.on("connect", () => {
  //     setIsConnected(true);
  //   });
  //   socket.on("disconnect", () => {
  //     setIsConnected(false);
  //   });
  //   socket.on("notify", () => {});

  //   setTimeout(() => {
  //     toast(Msg);
  //   }, 1000);

  //   return () => {
  //     socket.off("connect");
  //     socket.off("disconnect");
  //     socket.off("notify");
  //   };
  // }, []);

  const appRoutes = useMemo(
    () =>
      protectedRoutes.map((route) => {
        return route?.component ? (
          <Route
            key={route?.id}
            path={route?.path}
            element={
              !!accessToken ? (
                route?.component
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
        ) : null;
      }),
    [accessToken]
  );

  const appUnprotected = unprotected.map((route) => {
    return route?.component ? (
      <Route key={route?.id} path={route?.path} element={route?.component} />
    ) : null;
  });

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {appUnprotected}
        <Route path="/" element={<Layout />}>
          {appRoutes}
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
