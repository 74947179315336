import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  CircularProgress,
  Box,
  IconButton,
} from "@mui/material";
import { ReactComponent as CloseIcon } from "../assets/icons/close.svg";
import { useMemo, memo } from "react";

function DriverInfo({ driverData, selectedCarID, closeHandler }) {
  const rows = useMemo(
    () => [
      { id: driverData?.id, name: "Captain Name", value: driverData?.name },
      {
        id: driverData?.id,
        name: "Phone Number",
        value: driverData?.phone ? "+249 " + driverData?.phone : null,
      },
    ],
    [driverData]
  );

  return (
    <Box position="relative" bgcolor="white" padding={2} borderRadius={3}>
      <IconButton
        onClick={closeHandler}
        style={{
          position: "absolute",
          top: 2,
          right: 2,
          maxWidth: "min-content",
          boxShadow: 0,
        }}
      >
        <CloseIcon />
      </IconButton>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {rows &&
              rows?.map((row) => {
                return (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell variant="head">{row.name}</TableCell>
                    <TableCell>
                      {!row?.value || row?.id !== selectedCarID ? (
                        <CircularProgress size="1rem" />
                      ) : (
                        row?.value
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

DriverInfo.propTypes = {
  driverData: PropTypes.object,
  selectedCarID: PropTypes.number,
  closeHandler: PropTypes.func,
};

export default memo(DriverInfo);
