import React, { useState } from "react";
import PropTypes from "prop-types";
import { Stack, Button, Divider } from "@mui/material";

function ButtonsGroup({ buttonsData, onClick }) {
  const [active, setActive] = useState(buttonsData[0].value);

  const onCLickHandler = (e) => {
    const value = +e.target.value;
    setActive(value);
    onClick(value);
  };

  return (
    <Stack
      direction="row"
      bgcolor="white"
      p={1}
      divider={<Divider orientation="vertical" flexItem />}
      sx={{
        minWidth: "min-content",
        borderRadius: "0.7rem",
        boxShadow: "rgba(0,0,0,0.35) 0px 5px 15px",
      }}
    >
      {buttonsData?.map((data) => {
        return (
          <Button
            key={data.value}
            color={active === data?.value ? "primary" : "text"}
            value={data.value}
            onClick={onCLickHandler}
          >
            {data.label}
          </Button>
        );
      })}
    </Stack>
  );
}

ButtonsGroup.propTypes = {
  buttonsData: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  onClick: PropTypes.func,
};

export default ButtonsGroup;
