import React from "react";
import AnalyticsReport from "../pages/AnalyticsReport";
import Captains from "../pages/Captains";
import LiveTracking from "../pages/LiveTracking";
import UnassignedDeliveries from "../pages/UnassignedDeliveries";

const Login = React.lazy(() => import("../pages/Login"));

export const ID = {
  LIVE_TRACKING: "livetracking",
  LOGIN: "login",
  CAPTAINS: "captains",
  REPORT: "report",
  UNASSIGNED: "unassigned",
};
export const PATHS = {
  LIVE_TRACKING: "/",
  LOGIN: "/login",
  CAPTAINS: "/delivery-captains",
  REPORT: "/report",
  UNASSIGNED: "/unassigned-deliveries",
};

export const protectedRoutes = [
  {
    id: ID.LIVE_TRACKING,
    path: PATHS.LIVE_TRACKING,
    protected: true,
    component: <LiveTracking />,
  },
  {
    id: ID.CAPTAINS,
    path: PATHS.CAPTAINS,
    protected: true,
    component: <Captains />,
  },
  {
    id: ID.REPORT,
    path: PATHS.REPORT,
    protected: true,
    component: <AnalyticsReport />,
  },
  {
    id: ID.UNASSIGNED,
    path: PATHS.UNASSIGNED,
    protected: true,
    component: <UnassignedDeliveries />,
  },
];

export const unprotected = [
  {
    id: ID.LOGIN,
    path: PATHS.LOGIN,
    protected: false,
    component: <Login />,
  },
];
