import { Typography } from "@mui/material";
import ContentContainer from "../components/ContentContainer";

export default function UnassignedDeliveries() {
  return (
    <ContentContainer>
      <Typography variant="h4" my={3}>
        Unassigned Deliveries
      </Typography>
    </ContentContainer>
  );
}
