import React, { useCallback, useEffect, useState, useRef } from "react";
import MaterialReactTable from "material-react-table";
import { BiRefresh } from "react-icons/bi";
import { BsDownload } from "react-icons/bs";

import { Button, Stack } from "@mui/material";
import useAxiosToken from "../hooks/useAxiosToken";
import { ExportToCsv } from "export-to-csv";

const init = [
  {
    id: 50406,
    name: { ar: "Mohammed Youssif Algizouli Ahmed" },
    phoneNumber: "923631333",
    status: "OnRide",
    since: "1 seconds ago",
    lastSeen: "2022-10-04 12:13:00",
  },
  {
    id: 50241,
    name: { ar: "Jamal Omer Ahmed Omer" },
    phoneNumber: "993871719",
    status: "OnRide",
    since: "2 seconds ago",
    lastSeen: "2022-10-04 12:12:59",
  },
  {
    id: 50356,
    name: { ar: "Almahi Albaghir Almahi Mohammed" },
    phoneNumber: "996116474",
    status: "OnRide",
    since: "2 seconds ago",
    lastSeen: "2022-10-04 12:12:58",
  },
  {
    id: 50403,
    name: { ar: "Mohammed Alamin Alla Aldeen" },
    phoneNumber: "923456814",
    status: "OnRide",
    since: "20 seconds ago",
    lastSeen: "2022-10-04 12:12:40",
  },
  {
    id: 50407,
    name: { ar: "Mohialdeen Mohammed Mohialdeen Mohammed" },
    phoneNumber: "903131119",
    status: "Available",
    since: "6 seconds ago",
    lastSeen: "2022-10-04 12:12:54",
  },
  {
    id: 50405,
    name: { ar: "Emad Yassir Abdulrhman Abkar" },
    phoneNumber: "927514425",
    status: "Available",
    since: "15 seconds ago",
    lastSeen: "2022-10-04 12:12:46",
  },
  {
    id: 50246,
    name: { ar: "Monzer Abualmajad Ibrahim Osman" },
    phoneNumber: "928514369",
    status: "Offline",
    since: "8 minutes ago",
    lastSeen: "2022-10-04 12:04:01",
  },
  {
    id: 50243,
    name: { ar: "Muahanned Haitham Abdulrhamn Abakar" },
    phoneNumber: "963557210",
    status: "Offline",
    since: "1 hours ago",
    lastSeen: "2022-10-04 10:52:47",
  },
  {
    id: 50242,
    name: { ar: "AbdAllah Gamar Abdultam Mohammed" },
    phoneNumber: "110050778",
    status: "Offline",
    since: "1 hours ago",
    lastSeen: "2022-10-04 10:15:28",
  },
  {
    id: 50245,
    name: { ar: "Mudathir Abdulkhalig Mohammed Abdulrahim" },
    phoneNumber: "112100512",
    status: "Offline",
    since: "13 hours ago",
    lastSeen: "2022-10-03 22:37:54",
  },
  {
    id: 50450,
    name: { ar: "Ashraf Eltahir" },
    phoneNumber: "995006600",
    status: "Offline",
    since: "21 hours ago",
    lastSeen: "2022-10-03 14:39:51",
  },
];
export const btnStyle = { px: 0.5, py: 0.3, fontSize: "0.8rem" };

function flattenObject(ob) {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] == "object" && ob[i] !== null) {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + "." + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}

function getDate() {
  let today = new Date();
  let dd = today.getDate();

  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }
  today = dd + "-" + mm + "-" + yyyy;
  return today;
}

export default React.memo(function Table({
  url,
  columns,
  autoRefreshDuration,
  sortData,
  downloadFile = false,
  fileName = "GO",
}) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isError, setIsError] = useState(false);

  const previousController = useRef();

  const axios = useAxiosToken();

  const fetchData = useCallback(async () => {
    setIsError(false);

    if (previousController.current) {
      previousController.current.abort();
    }

    const controller = new AbortController();
    const signal = controller.signal;

    previousController.current = controller;

    if (!data?.length) {
      // console.log("todo: data length = 0");
      setIsLoading(true);
    } else {
      // console.log("todo: data length > 0");
      setIsRefetching(true);
    }

    try {
      const response = await axios.get(url, { signal });
      const responseData = sortData ? sortData(response.data) : response.data;
      setData(responseData);
    } catch (error) {
      console.log(error);
      setIsError(true);
    }
    setIsLoading(false);
    setIsRefetching(false);
  }, [data?.length, url, axios, sortData]);

  useEffect(() => {
    let interval;
    if (autoRefreshDuration) {
      interval = setInterval(() => {
        fetchData();
      }, autoRefreshDuration);
    }
    fetchData();
    return () => {
      autoRefreshDuration && clearInterval(interval);
    };
  }, [fetchData, autoRefreshDuration]);

  const headCellProps = useCallback(() => {
    return {
      align: "center",
    };
  }, []);

  const download = () => {
    const csvOptions = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      filename: fileName + "_" + getDate(),
      headers: columns.map((c) => {
        console.log(c.header);
        return c.header;
      }),
    };

    const csvExporter = new ExportToCsv(csvOptions);

    let flatObject = data.map((x) => flattenObject(x));
    // console.log(flatObject);
    csvExporter.generateCsv(flatObject);
  };

  const topAction = ({ table }) => {
    // console.log(table.getRowModel().rows);
    // console.log(table.getRow());
    return (
      <Stack flexWrap="nowrap" gap={1} flexDirection="row" mb={2}>
        {downloadFile && (
          <Button
            variant="contained"
            sx={{ color: "white", boxShadow: 0, borderRadius: 1 }}
            size="small"
            color="secondary"
            startIcon={<BsDownload />}
            onClick={download}
          >
            Download Report
          </Button>
        )}
        <Button
          variant="contained"
          sx={{ color: "white", boxShadow: 0, borderRadius: 1 }}
          size="small"
          color="grey"
          onClick={fetchData}
          startIcon={<BiRefresh />}
        >
          Refresh
        </Button>
      </Stack>
    );
  };

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      initialState={{ density: "compact" }}
      muiTableToolbarAlertBannerProps={
        isError
          ? {
              color: "error",
              children: "Error loading data",
            }
          : undefined
      }
      muiTablePaperProps={{
        sx: {
          maxWidth: "100%",
          boxShadow: "none",
          "& .MuiToolbar-root": {
            boxShadow: "none",
          },
        },
      }}
      enableSorting={false}
      enableDensityToggle={false}
      enableFilters={false}
      enableHiding={false}
      enableColumnActions={false}
      enableRowNumbers={true}
      // enableBottomToolbar={false}
      enablePagination={true}
      state={{
        isLoading,
        showAlertBanner: isError,
        showProgressBars: isRefetching,
      }}
      muiTableHeadCellProps={headCellProps}
      muiTableBodyCellProps={{
        align: "center",
      }}
      enableRowActions={false}
      renderTopToolbarCustomActions={topAction}
    />
  );
});
