import { createContext, useCallback, useState } from "react";

const ACCESS_TOKEN = "ACCESS_TOKEN";
const VIEW_RIDES = "VIEW_RIDES";

const AuthContext = createContext({
  accessToken: "",
  viewRides: [],
  saveAllowedRides: () => {},
  saveAccessToken: (access) => {},
  logout: () => {},
});

const retrieveStoredToken = (key) => {
  const tokenData = localStorage.getItem(key);
  return tokenData ? tokenData : "";
};

const retrieveStoredRideTypes = () => {
  const typesData = localStorage.getItem(VIEW_RIDES);
  return typesData ? JSON.parse(typesData) : [""];
};

export const AuthProvider = ({ children }) => {
  const [viewRides, setViewRides] = useState(retrieveStoredRideTypes());
  const [access, setAccess] = useState(retrieveStoredToken(ACCESS_TOKEN));

  const accessTokenHandler = (token) => {
    setAccess(token);
    localStorage.setItem(ACCESS_TOKEN, token);
  };

  const allowedHandler = (allowed) => {
    setViewRides(allowed);
    localStorage.setItem(VIEW_RIDES, JSON.stringify(allowed));
  };

  const logoutHandler = useCallback(() => {
    setAccess("");
    setViewRides([""]);
    localStorage.setItem(ACCESS_TOKEN, "");
    localStorage.setItem(VIEW_RIDES, JSON.stringify([""]));
  }, []);

  const value = {
    accessToken: access,
    viewRides: viewRides,
    saveAccessToken: accessTokenHandler,
    saveAllowedRides: allowedHandler,
    logout: logoutHandler,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
