import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import ContentContainer from "../components/ContentContainer";
import Table from "../components/Table";

export default function AnalyticsReport() {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "dateId",
        header: "Date",
        size: 10,
      },
      {
        accessorKey: "captainId",
        header: "Captain ID",
        size: 10,
      },
      {
        accessorKey: "captainName",
        header: "Captain Name",
        size: 10,
      },
      {
        accessorKey: "phoneNumber",
        header: "Phone Number",
        size: 10,
        Cell: ({ cell }) => {
          return <span>+249 {cell?.row.original.phoneNumber}</span>;
        },
      },
      {
        accessorKey: "acceptedTrip",
        header: "Accepted Trips",
        size: 10,
      },
      {
        accessorKey: "timeToPickup",
        header: "Time to Pickup",
        size: 10,
      },
      {
        accessorKey: "timeToDrop",
        header: "Time to Drop-off",
        size: 10,
      },
      {
        accessorKey: "ignoreTrips",
        header: "Ignore Trips",
        size: 10,
      },
      {
        accessorKey: "rejectedTrips",
        header: "Rejected Trips",
        size: 10,
      },
      {
        accessorKey: "completedTrips",
        header: "Completed Trips",
        size: 10,
      },
      {
        accessorKey: "postassigned",
        header: "Post- Assigned",
        size: 10,
      },
      {
        accessorKey: "distance",
        header: "Distance",
        size: 10,
      },
      {
        accessorKey: "avaHours",
        header: "Average Hours",
        size: 10,
      },
    ],
    []
  );
  return (
    <ContentContainer>
      <Typography variant="h4" my={3}>
        Analytics Report
      </Typography>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Today" value="1" />
            <Tab label="Yesterday" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Table
            url="captains"
            columns={columns}
            downloadFile
            fileName="Daily_Delivery"
          />
        </TabPanel>
        <TabPanel value="2">
          <Table
            url="captains"
            columns={columns}
            downloadFile
            fileName="Daily_Delivery"
          />
        </TabPanel>
      </TabContext>
    </ContentContainer>
  );
}
