import axios from "axios";
// import { toast } from "react-toastify";

export const BASE_URL = "https://map.goteclabs.com:7000";

export default axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
});

export const axiosToken = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
});
