import { Box, Stack } from "@mui/material";

export default function ContentContainer({ children }) {
  return (
    <Stack
      pt={{ xs: 3, sm: 7 }}
      pb={5}
      px={{ xs: 2, sm: 5 }}
      flexDirection="column"
      gap={5}
    >
      <Box bgcolor="white" py={2} px={3} borderRadius={5} boxShadow={1}>
        {children}
      </Box>
    </Stack>
  );
}
